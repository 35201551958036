<template>
    <main v-if="entity">
        <div
                class="order-title info-title"
                @click="switchExpend(0, expendSwitch[0])"
        >
            Order Detail
            <img
                    class="expend"
                    :class="expendSwitch[0] ? 'expended' : ''"
                    src="@/assets/img/expend.png"
                    alt=""
            />
        </div>
        <ul class="order-ul" v-show="expendSwitch[0]">
            <li>
                <div class="order-date">Order Number:
                    <span>{{ entity.orderNum }}</span>
                </div>
                <div class="order-date">
                    Total Price:
                    <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
                        <span v-if="entity.totalPrice">${{Number(entity.totalPrice).toFixed(2)}}</span>
                    </template>
                    <a v-else href="https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1"><span
                            style="color: #444">CS</span></a>
                </div>
                <div class="order-date">web/product/elasticsearch.html
                    Order Date:
                    <span>{{ dateFormat(entity.createTime) }}</span>
                </div>
                <div class="order-date">
                    Order Status:
                    <span v-if="entity.orderStatus ===0">Unconfirmed</span>
                    <span v-else-if="entity.orderStatus ===1">Confirmed</span>
                    <span v-else>Transporting</span>
                    <span v-if="entity.orderStatus ===1" @click="PayNow(entity.orderNum)" class="pay-now">Paypal</span>
                </div>

            </li>
        </ul>
        <div class="goods-info-wapper">
            <div class="goods-wapper">
                <div class="info-title" @click="switchExpend(1, expendSwitch[1])">
                    Product Information
                    <img
                            class="expend"
                            :class="expendSwitch[1] ? 'expended' : ''"
                            src="@/assets/img/expend.png"
                            alt=""
                    />
                </div>
                <ul class="product-module" v-show="expendSwitch[1]">
                    <li class="product-li" v-for="(item, index) in list" :key="index">
                        <router-link
                                :to="{
                name: 'productdetail',
                params: { gid: item.gid, classId: item.classID },
              }"
                                class="hot-container"
                        >
                            <div
                                    class="add-car"
                                    style="right: 0px; width: 40.5px;"
                            >
                                <span>X {{ item.qty }}</span>
                            </div>
                            <div class="product-title">{{ item.title }}</div>
                            <div class="product-img">
                                <img :src="item.pic || productDefaultPic" alt=""/>
                            </div>
                            <div class="product-info">
                                <p v-if="item.spec">
                                    <span>{{ item.itemNo }}</span>
                                    , &nbsp;
                                    <span>{{ item.spec }}</span>
                                </p>
                                <p v-else>
                                    <span>{{ item.itemNo }}</span>
                                </p>
                            </div>

                            <div class="product-brand">
                                <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
                                    <span class="product-price">${{Number(item.price).toFixed(2) || ''}}</span>
                                </template>
                                <span v-else style="color: transparent">X {{ item.qty }}</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="product-li">
                        <a>
                            <div
                                    class="add-car"
                                    style="right: 0px; width: 40.5px;"
                            >
                                <span>X 1</span>
                            </div>
                            <div class="product-title">Packaging & Freight</div>
                            <div class="product-img">
                                <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/571537662b085738c3a7e783bb0dd51e.png"
                                     alt=""/>
                            </div>
                            <div class="product-info">
                                <p>
                                    <span>Free Shipping Over ${{postageFee}}</span>
                                </p>
                            </div>
                            <div class="product-brand">
                                <span class="product-price">${{entity.farePrice}}</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="logistics-info-wapper">
            <div class="info-title" @click="switchExpend(2, expendSwitch[2])">
                Receiving Information
                <img
                        class="expend"
                        :class="expendSwitch[2] ? 'expended' : ''"
                        src="@/assets/img/expend.png"
                        alt=""
                />
            </div>
            <div v-show="expendSwitch[2]">
                <p>Receiver:{{ entity.realName }}</p>
                <p>Email:{{ entity.receivingMailbox }}</p>
                <p>Address:{{ entity.address }}</p>
                <p>Postcode:{{ entity.receivingPostcode }}</p>
                <div class="logistics" v-if="entity.deliverNO && entity.deliverType">
                    <p>Express：{{ entity.deliverType }}</p>
                    <p>Tracking number ：{{ entity.deliverNO }}</p>
                    <a
                            href="/M/User/Wuliu?DeliverType=${entity.deliverType!}&DeliverNO=${entity.deliverNO!}"
                    >Logistics</a
                    >
                </div>
            </div>
        </div>
    </main>
</template>

<script lang="ts">
    import {defineComponent, ref, inject} from "vue";
    import {useRoute} from "vue-router";
    import axios from "@/api/axios";
    import Toast from "@/components/UI/Toast";
    import dateFormat from "@/utils/dateFormat";

    export default defineComponent({
        name: "OrderDetail",
        setup() {
            //   /M/User/OrderDetail/3153
            const userInfo = inject("userInfo") as any;
            const biaowuPriceOpen = ref();
            const productDefaultPic = ref();
            const postageFee = ref();
            const entity = ref(null);
            const list = ref([]);
            const route = useRoute();
            const id = route.query.id;
            const expendSwitch = ref([true, true, false]);
            axios
                .get("/M/User/OrderDetail/" + id, {params: {validate: true}})
                .then((res) => {
                    if (res.data.success) {
                        entity.value = res.data.obj.entity;
                        list.value = res.data.obj.entity.orderDetail;
                        biaowuPriceOpen.value = res.data.obj.biaowuPriceOpen
                        productDefaultPic.value = res.data.obj.productDefaultPic
                        postageFee.value = res.data.obj.postageFee
                    } else {
                        Toast({
                            type: "error",
                            title: res.data.msg,
                        });
                    }
                })
                .catch((e) => {
                    Toast({
                        type: "error",
                        title: e.message || e,
                    });
                });

            function switchExpend(index: number, value: boolean) {
                expendSwitch.value[index] = !value;
            }

            function PayNow(orderNum: any) {
                window.location.href = "/Checkout?orderNum=" + orderNum;
            }

            return {
                entity,
                dateFormat,
                list,
                expendSwitch,
                switchExpend,
                biaowuPriceOpen,
                productDefaultPic,
                postageFee,
                userInfo,
                PayNow
            };
        },
    });
</script>

<style lang="scss" scoped>
    main {
        padding-bottom: 109.987px;
        width: 355.013px;
        margin: 0 auto;
    }

    .pay-now {
        display: inline-block;
        width: 120px;
        height: 30px;
        background: #df0024;
        text-align: center;
        line-height: 30px;

        color: #fff;
        font-size: 16px;
        float: right;
        margin-top: -10px
    }

    /**

        菜单导航
      */
    .menu-nav {
        overflow: hidden;
        height: 45px;
        line-height: 45px;
        border-bottom: 6.487px solid #f4f4f4;
    }

    .menu-nav li {
        float: left;
        width: 50%;
        text-align: center;
    }

    .menu-nav li a {
        display: block;
        text-align: center;
        line-height: 45px;
        width: 100%;
        font-size: 16.012px;
        color: #666;
    }

    .menu-nav .active a {
        color: #df0024;
    }

    .order-ul li {
        box-sizing: border-box;
        padding: 0 15px;

        overflow: hidden;

        position: relative;
    }

    .order-ul .title {
        width: 100%;
        height: 40.012px;
        line-height: 40.012px;
        border-bottom: 1px solid #eee;
        font-size: 15px;
        color: #444;
        position: relative;
    }

    .price {
        position: absolute;
        right: 0;
        top: 0;
        height: 40.012px;
        line-height: 40.012px;
        color: rgb(102, 102, 102);
    }

    .order-date {
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-top: 10.013px;

        font-size: 15px;
        box-sizing: border-box;
        color: #666;
        position: relative;
    }

    .order-btn {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -11.25px;
        text-align: center;
        width: 79.987px;
        height: 22.5px;
        border-radius: 15px;

        line-height: 22.5px;

        font-size: 13.988px;
        color: #df0034;
        border: solid 1px #df0024;
    }

    .balance {
        position: fixed;
        color: #666;
        bottom: 49.987px;
        left: 0;
        right: 0;
        height: 49.987px;
        line-height: 49.987px;
        padding-left: 10.013px;
        border-top: 1.012px solid #f4f4f4;
        border-bottom: 1.012px solid #f4f4f4;
        font-size: 18px;
        font-weight: bold;
        background-color: #fff;
        z-index: 100;
    }

    .empty {
        padding-top: 160.013px;
        text-align: center;
        padding-bottom: 49.987px;

        color: #9f9f9f;
        font-size: 13.012px;
    }

    .empty img {
        width: 132.487px;
        height: 103.988px;
        display: block;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    .empty a {
        font-size: 13.012px;
        color: #df0024;
    }

    .order-title {
        color: #52ba65;
        padding-left: 15px;
        font-size: 18px;
    }

    .goods-wapper,
    .logistics-info-wapper {
        width: 355.013px;
        margin: 0 auto;
    }

    .info-title {
        color: #444444;
        font-size: 15px;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #eee;

        position: relative;
        padding-left: 7px;

        .expend {
            width: 16px;
            height: auto;
            vertical-align: middle;
            transform: rotateZ(0);
            transition: transform 0.5s;
        }

        .expended {
            transform: rotateZ(-180deg);
            transition: transform 0.5s;
        }
    }

    .info-title::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 3px;
        height: 14px;
        transform: translateY(-50%);
        background-color: #aa183c;
    }

    .goods-info-wapper img {
        width: 90px;
        height: 90px;
        vertical-align: top;
    }

    .good-info {
        display: inline-block;
        padding-top: 4.988px;
        width: 250.012px;
    }

    .good-info p {
        color: #444444;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-top: 4.988px;
        margin: 0;
    }

    .logistics-info-wapper p {
        color: #444444;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-top: 4.988px;
        margin: 0;
        margin-bottom: 15px;
    }

    .logistics {
        position: relative;
    }

    .logistics a {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        width: 85.013px;
        height: 28.012px;
        border-radius: 13.988px;
        border: solid 1px #cccccc;
        line-height: 28.012px;
        text-align: center;
        color: #666666;
        font-size: 15px;
    }

    /* 产品列表 */

    .product-module .product-li {
        width: 100%;
        padding: 0.4rem 0.267rem;
        box-sizing: border-box;
        font-size: 0.347rem;
        color: #999;
        padding-left: 2.267rem;
        position: relative;
        border-bottom: 0.027rem solid #f4f4f4;
        position: relative;
    }

    .product-module .product-img {
        position: absolute;
        left: 0.267rem;
        top: 0.5rem;
    }

    .product-module .product-img img {
        width: 1.733rem;
        height: 1.733rem;
        object-fit: cover;
    }

    .product-module .product-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 0.427rem;
        color: #333;
        line-height: 1.5;
        min-height: 0.587rem;
        padding-right: 1.13rem;
        box-sizing: border-box;
        margin-bottom: 0.133rem;
        position: relative;
        margin-top: 0.05rem;
    }

    .product-module .product-info {
        // font-size: 0.373rem;
        font-size: 12px;
        color: #999;
        line-height: 0.427rem;
        margin-bottom: 0.2rem;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
    }

    .product-module .product-brand {
        color: #999;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .product-module .product-brand .product-price {
        color: #df0024;
        font-size: 12px;
    }

    .product-module .product-brand span {
        padding-right: 0.133rem;
        font-size: 0.373rem;
        color: #999;
        line-height: 0.427rem;
    }

    .product-module .add-car {
        position: absolute;
        right: 0.613rem;
        // top: 0.5rem;
        top: 0.57rem;
        width: 0.44rem;
        height: 0.413rem;
        z-index: 9;
        // padding: 0.267rem;
        padding-top: 0;
        padding-right: 0.267rem;
        text-align: right;
        color: #df0024;
    }

    .product-module .add-car img {
        width: 100%;
        height: 100%;
    }
</style>
